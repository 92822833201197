import { PadPosition, PresentationType } from "../presentationType";

export class HexPresentation extends PresentationType {
    public constructor() {
        super("hex");
    }

    public toInternalRepresentation(input: string): string {
        return this.padToFullByte(
            input
                .replace("0x", "")
                .split("")
                .map((char: string) => Number.parseInt(char, 16))
                .map((n) => n.toString(2))
                .map((binaryRepresentation) => binaryRepresentation.padStart(4, "0"))
                .join(""),
            PadPosition.START
        );
    }

    public fromInternalRepresentation(numberPresentation: string): string {
        return BigInt("0b" + numberPresentation).toString(16);
    }

    public getAllowedCharacters(): string[] {
        return [...this.getNumbersFromZeroTo(9), "A", "B", "C", "D", "E", "F", "a", "b", "c", "d", "e", "f", "x"];
    }
}
