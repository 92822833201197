import { PadPosition } from "../presentationType";
import { BinaryPresentation } from "./binaryPresentation";

export class BinaryLittleEndianBitBigEndianPresentation extends BinaryPresentation {
    public constructor() {
        super("binary-ByteLittleEndian-BitBigEndian");
    }

    public toInternalRepresentation(input: string): string {
        return this.splitIntoPiecesOfSize(this.padToFullByte(input, PadPosition.END), 8).reverse().join("");
    }

    public fromInternalRepresentation(numberPresentation: string): string {
        return this.splitIntoPiecesOfSize(this.padToFullByte(numberPresentation, PadPosition.START), 8).reverse().join("");
    }
}