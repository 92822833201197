import { Link, Typography } from "@mui/material";
import { useState } from "react";
import ChangelogDialog from "./changelogDialog";

export default function Footer() {
    const [open, setOpen] = useState<boolean>(false);

    return (
        <footer style={{bottom: "0px", position: "absolute", textAlign: "center", width: "100%"}}>
            <Typography> Darp Converter Version <Link onClick={() => setOpen(true)}>0.3.1</Link></Typography>
            <ChangelogDialog open={open} close={() => setOpen(false)} />
        </footer>
    );
}