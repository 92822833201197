import { PresentationType } from "../presentationType";

export class AsciiPresentation extends PresentationType {
    public constructor() {
        super("ascii");
    }

    public toInternalRepresentation(input: string): string {
        return input.split("")
            .map((char: string) => char.charCodeAt(0))
            .map((n) => n.toString(2))
            .map((binaryRepresentation) => binaryRepresentation.padStart(8, "0"))
            .join("");
    }

    public fromInternalRepresentation(numberPresentation: string): string {
        return numberPresentation.match(/.{1,8}/g)
            ?.map((byte) => this.convertFromAscii(parseInt(byte, 2)))
            .join("")
            ?? "";
    }

    private convertFromAscii(integer: number): string {
        const asciiMap = new Map<number, string>([
            [0, "NUL"],
            [1, "SOH"],
            [2, "STX"],
            [3, "ETX"],
            [4, "EOT"],
            [5, "ENQ"],
            [6, "ACK"],
            [7, "BEL"],
            [8, "BS"],
            [9, "TAB"],
            [10, "LF"],
            [11, "VT"],
            [12, "FF"],
            [13, "CR"],
            [14, "SO"],
            [15, "SI"],
            [16, "DLE"],
            [17, "DC1"],
            [18, "DC2"],
            [19, "DC3"],
            [20, "DC4"],
            [21, "NAK"],
            [22, "SYN"],
            [23, "ETB"],
            [24, "CAN"],
            [25, "EM"],
            [26, "SUB"],
            [27, "ESC"],
            [28, "FS"],
            [29, "GS"],
            [30, "RS"],
            [31, "US"],
            [32, "SP"],
            [127, "DEL"],
        ]);

        if (asciiMap.get(integer)) {
            return `'${asciiMap.get(integer)}'`;
        }

        return String.fromCharCode(integer);
    }

    public getAllowedCharacters(): string[] {
        const asciiCharacters = ' !"#$%&\'()*+,-./0123456789:;<=>?@ABCDEFGHIJKLMNOPQRSTUVWXYZ[\\]^_`abcdefghijklmnopqrstuvwxyz{|}~';

        return asciiCharacters.split("");
    }
}