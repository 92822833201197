import { PadPosition, PresentationType } from "../presentationType";

export class OctalPresentation extends PresentationType {
    public constructor() {
        super("octal");
    }

    public toInternalRepresentation(input: string): string {
        return this.padToFullByte(BigInt("0o" + input).toString(2), PadPosition.START);
    }

    public fromInternalRepresentation(numberPresentation: string): string {
        return BigInt("0b" + numberPresentation).toString(8);
    }

    public getAllowedCharacters(): string[] {
        return this.getNumbersFromZeroTo(7);
    }
}