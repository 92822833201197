import { MenuItem, Select } from "@mui/material";

import { converter } from "./converter";

interface SelectTypeProps {
    value: string;
    label: string;
    defaultSelected: string;
    onChange: (value: string) => void;
}

export default function SelectType(props: SelectTypeProps) {
    const availableTypes = converter.getAvailableTypes().map(
        (type) => (<MenuItem value={type}>{type}</MenuItem>)
    );

    return (
        <>
            <Select
                sx={{ m: 1}}
                label={props.label}
                onChange={(event) => {
                    const selected = event.target.value as string;

                    props.onChange(selected);
                }}
                value={props.value}
                style={{width: "100%"}}
            >
                {availableTypes}
            </Select>
        </>
    );
}