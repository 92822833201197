import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button, Slide, Typography } from "@mui/material";
import { TransitionProps } from "@mui/material/transitions";
import React from "react";

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
      children: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>,
  ) {
    return <Slide direction="up" ref={ref} {...props} />;
});
  
interface ChangelogDialogProps {
    open: boolean;
    close: () => void;
}

export default function ChangelogDialog(props: ChangelogDialogProps) {
    return (
        <Dialog
            open={props.open}
            TransitionComponent={Transition}
            keepMounted
            onClose={props.close}
        >
            <DialogTitle>Changelog</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    <Typography variant="h6" sx={{m: 0}}>0.3.1</Typography>
                    <Typography variant="body2" sx={{m: 0}}>Fixed: Output-Type als Input übernehmen, Bit-Auffüllen</Typography>
                    <br />
                    <Typography variant="h6" sx={{m: 0}}>0.3</Typography>
                    <Typography variant="body2" sx={{m: 0}}>Added: "Als Eingabe" verwenden, Fixed: Hex-Conversion</Typography>
                </DialogContentText>
            </DialogContent>
            <DialogActions>
            <Button onClick={props.close}>Schließen</Button>
            </DialogActions>
      </Dialog>
    );
}