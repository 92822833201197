interface HorizontalContainerProps {
    children: React.ReactNode;
}

export default function HorizontalContainer(props: HorizontalContainerProps) {
    return (
        <div style={{width: "100%", display: "flex", alignItems: "center"}}>
            {props.children}
        </div>
    )
}