import { PadPosition, PresentationType } from "../presentationType";

export class IntegerPresentation extends PresentationType {
    public constructor() {
        super("decimal");
    }

    public toInternalRepresentation(input: string): string {
        return this.padToFullByte(BigInt(input).toString(2), PadPosition.START);
    }

    public fromInternalRepresentation(numberPresentation: string): string {
        return BigInt("0b" + numberPresentation).toString(10);
    }

    public getAllowedCharacters(): string[] {
        return this.getNumbersFromZeroTo(9);
    }
}