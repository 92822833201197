import { PresentationType } from "./presentationType";
import { HexPresentation } from "./presentationTypes/hexPresentation";
import { IntegerPresentation } from "./presentationTypes/integerPresentation";
import { BinaryBigEndianBitBigEndianPresentation } from "./presentationTypes/binaryBigEndianPresentation";
import { BinaryLittleEndianBitBigEndianPresentation } from "./presentationTypes/binaryLittleEndianBitBigEndianPresentation";
import { BinaryLittleEndianBitLittleEndianPresentation } from "./presentationTypes/binaryLittleEndianBitLittleEndianPresentation";
import { BinaryBigEndianBitLittleEndianPresentation } from "./presentationTypes/binaryBigEndianBitLittleEndianPresentation";
import { AsciiPresentation } from "./presentationTypes/asciiPresentation";
import { OctalPresentation } from "./presentationTypes/octalPresentation";

export type ConversionResult = {
    representation: string;
    representationName: string;
};

class Concerter {
    private presentationTypes: PresentationType[] = [];

    public constructor() {
        this.presentationTypes.push(
            new IntegerPresentation(),
            new HexPresentation(),
            new BinaryBigEndianBitBigEndianPresentation(),
            new BinaryLittleEndianBitBigEndianPresentation(),
            new AsciiPresentation(),
            new OctalPresentation(),
            new BinaryBigEndianBitLittleEndianPresentation(),
            new BinaryLittleEndianBitLittleEndianPresentation(),
        );
    }

    public getAvailableTypes(): string[] {
        return this.presentationTypes.map((type) => type.name);
    }

    public convertToAll(fromType: PresentationType, data: string, partsDelimiter: string): ConversionResult[] {
        const individualParts = this.splitIntoIndividualPartsAndSanitize(data, partsDelimiter, fromType)

        const convertIndividualParts = (individualPart: string, outType: PresentationType) => {
            return outType.fromInternalRepresentation(fromType.toInternalRepresentation(individualPart));
        };

        return this.presentationTypes.map((ouputType) => ({
            representationName: ouputType.name,
            representation: individualParts.map((part) => convertIndividualParts(part, ouputType)).join(partsDelimiter),
        }));
    }

    public getPresentationTypeByName(name: string): PresentationType | null {
        return this.presentationTypes.find((type) => type.name === name) ?? null;
    }

    public sanitizeInput(input: string, presentationType: PresentationType) {
        const allowedChars = presentationType.getAllowedCharacters().join("");

        return input.match(new RegExp(`[${allowedChars}]`, "g"))?.join("") ?? "";
    }

    public splitIntoIndividualPartsAndSanitize(input: string, delimiter: string, inputType: PresentationType) {
        return input.split(delimiter).map((part) => this.sanitizeInput(part, inputType));
    }
}

export const converter = new Concerter();