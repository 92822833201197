import { useMemo, useState } from 'react';
import { Box, TextField, Typography } from '@mui/material';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';

import SelectType from './SelectType';
import { converter } from './converter';
import Footer from './components/footer';
import ResultsTable from './components/resultsTable';
import { PresentationType } from './presentationType';
import HorizontalContainer from './components/horizontalContainer';

export default function ConvertPage() {
    const [fromType, setFromType] = useState<PresentationType | null>(converter.getPresentationTypeByName("ascii"));

    const [input, setInput] = useState<string>("Hallo");
    const [delimiter, setDelimiter] = useState<string>("-");

    const changeInputAndInputPresentation = (input: string, representationName: string) => {
        setInput(input);
        setFromType(converter.getPresentationTypeByName(representationName));
    }

    const result = useMemo(() => {
        if (!fromType) {
            return [];
        }

        try {
            return converter.convertToAll(fromType, input, delimiter);
        } catch (e) {}

        return [];
    }, [input, fromType, delimiter]);

    return (
        <>
            <div style={{margin: "2rem", padding: "2rem", textAlign: "center", display: "flex"}}>
                <Box sx={{ display: "flex", flexDirection: "column", width: "100%" }}>
                    <Typography variant="h4" sx={{m: 0}}>Converter</Typography>

                    <HorizontalContainer>
                        <SelectType
                            label="From"
                            value={fromType?.name ?? ""}
                            onChange={(name) => setFromType(converter.getPresentationTypeByName(name))}
                            defaultSelected="ascii"
                        />

                        <TextField
                            label="Trennzeichen"
                            onChange={(event) => setDelimiter(event.target.value)}
                            defaultValue="-"
                        />
                    </HorizontalContainer>

                    <HorizontalContainer>
                        <TextField
                            style={{width: "100%"}}
                            defaultValue="Hallo"
                            sx={{ m: 1}}
                            variant="filled"
                            label="Input"
                            value={input}
                            onChange={(event) => { 
                                setInput(event.target.value);
                            }}
                        />
                    </HorizontalContainer>

                    <ArrowDownwardIcon fontSize='large' style={{marginLeft: "auto", marginRight: "auto"}} />

                    <ResultsTable results={result} setInput={changeInputAndInputPresentation} />
                </Box>
            </div>

            <Footer />
        </>
    );
}
