import { PadPosition } from "../presentationType";
import { BinaryPresentation } from "./binaryPresentation";

export class BinaryBigEndianBitLittleEndianPresentation extends BinaryPresentation {
    public constructor() {
        super("binary-ByteBigEndian-BitLittleEndian");
    }

    public toInternalRepresentation(input: string): string {
        return this.splitIntoPiecesOfSize(this.padToFullByte(input, PadPosition.END), 8)
            .map(this.reverseString)
            .join("");
    }

    public fromInternalRepresentation(numberPresentation: string): string {
        return this.splitIntoPiecesOfSize(this.padToFullByte(numberPresentation, PadPosition.START), 8)
            .map((byte) => this.reverseString(byte)).join("");
    }
}
