import { createTheme, ThemeProvider } from "@mui/material/styles";

import ConvertPage from "./ConvertPage";

export default function App() {
    const theme = createTheme({});

    return (
        <ThemeProvider theme={theme}>
            <ConvertPage />
        </ThemeProvider>
    )
}