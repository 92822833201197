import { PadPosition } from "../presentationType";
import { BinaryPresentation } from "./binaryPresentation";

export class BinaryBigEndianBitBigEndianPresentation extends BinaryPresentation {
    public constructor() {
        super("binary-ByteBigEndian-BitBigEndian");
    }

    public toInternalRepresentation(input: string): string {
        return this.padToFullByte(input, PadPosition.START);
    }

    public fromInternalRepresentation(numberPresentation: string): string {
        return numberPresentation;
    }
}
