import { Navigation } from "@mui/icons-material";
import { Fab, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material";

import { ConversionResult } from "../converter";

interface ResultsTableProps {
    results: ConversionResult[];
    setInput: (input: string, inputRepresentation: string) => void;
}

export default function ResultsTable(props: ResultsTableProps) {
    return (
        <TableContainer sx={{m: 1}} component={Paper}>
            <Table size="small">
                <TableHead>
                    <TableCell></TableCell>
                    <TableCell width={"100rem"}><b>Presented as</b></TableCell>
                    <TableCell><b>Data</b></TableCell>
                </TableHead>
                <TableBody>
                    {props.results.map((result) => (
                        <TableRow>
                            <TableCell>
                                <Fab
                                    variant="extended"
                                    size="small"
                                    onClick={() => {
                                        props.setInput(result.representation, result.representationName);
                                    }}
                                >
                                    <Navigation sx={{ mr: 1 }} />
                                    <Typography fontSize={10}>Als&nbsp;Eingabe</Typography>
                                </Fab>
                            </TableCell>
                            <TableCell>{result.representationName}</TableCell>
                            <TableCell>{result.representation}</TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
}