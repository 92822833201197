export enum PadPosition {
    START = "START",
    END = "END"
}


export abstract class PresentationType {
    public constructor(public name: string) {
    }

    public abstract toInternalRepresentation(input: string): string;
    public abstract fromInternalRepresentation(numberPresentation: string): string;
    public abstract getAllowedCharacters(): string[];

    protected fillWithSeperator(representation: string, spacing: number): string {
        return representation.match(new RegExp(`.{1,${spacing}}`, "g"))?.join("-") ?? "";
    }

    protected splitIntoPiecesOfSize(representation: string, size: number): string[] {
        return representation.match(new RegExp(`.{1,${size}}`, "g")) ?? [];
    }

    protected getNumbersFromZeroTo(max: number): string[] {
        return Array(max + 1).fill(0).map((v,i) => i.toString());
    }

    protected reverseString(input: string) {
        return input.split("").reverse().join("");
    }

    protected padToFullByte(representation: string, padAt: PadPosition) {
        if (representation.length % 8 === 0) {
            return representation;
        }

        const paddingLenght = 8 - (representation.length % 8);

        const paddingFunction = (maxLenght: number, fillString: string) =>
            (padAt === PadPosition.START) ? representation.padStart(maxLenght, fillString) : representation.padEnd(maxLenght, fillString);

        return paddingFunction(representation.length + paddingLenght, "0");
    }
}